import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`Further Reading`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Efficient-market_hypothesis"
          }}>{`Efficient Market Hypothesis`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Conditional_expectation"
          }}>{`Conditional Expectation`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Global_Industry_Classification_Standard"
          }}>{`Industry Sectors`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Volatility_(finance)"
          }}>{`Volatility`}</a></li>
      </ul>
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/momentum-mean-reversion/"
          }}>{`Momentum & Mean-Reversion`}</a></li>
      </ul>
      <p><strong parentName="p">{`Related Strategies`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-dos-equis/"
          }}>{`TT's Dos Equis`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-equal-voltage/"
          }}>{`TT's Equal Voltage`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-vix-treasures/"
          }}>{`TT's VIX Treasures`}</a></li>
      </ul>
    </Aside>
    <p>{`While the Efficient Market Hypothesis suggests that no tactical asset allocation strategy should consistently alter the market's risk/return profile, this is not entirely true. There are known market anomalies, which we can exploit to create an edge. In this post, we take a closer look at the volatility anomaly.`}</p>
    <p>{`Further, we like to show our approach to developing strategies. We start by analyzing the anomaly and develop a plan to use it to our benefit. Only then do we implement the strategy and confirm that backtesting matches our expectations. This flow ensures that we have a full understanding of our edge and the conditions under which it exists. Further, this way we avoid being overly focused on optimizing for pretty backtest results.`}</p>
    <h2>{`Setting the Goal`}</h2>
    <p>{`Before we start implementing a strategy, we need to set clear and achievable goals. We need these goals to stay on track and benchmark our results against them.`}</p>
    <p>{`As a high-level concept, we want to invest in the U.S. stock market and U.S. Treasuries; both represented through diversified ETFs.`}</p>
    <p>{`In bull markets, we want to maximize our exposure to the U.S. stock market. Similarly, we want to reduce our exposure to U.S. stocks in bear markets and flee into the safety of U.S. Treasury bonds. Further, we want to tilt our portfolio away from risky industries and toward those with lower volatility. To implement the latter, we are considering the eleven S&P 500 sector-ETFs.`}</p>
    <p>{`In terms of performance, we want to beat the S&P 500 in the long term while only slightly lagging it during bull markets. The risk profile should be comparable to a passive 60/40 portfolio.`}</p>
    <h2>{`Understanding Conditional Expected Returns`}</h2>
    <p>{`To visualize market anomalies, charts of conditional expected returns come in handy. These charts plot the expected future performance of an asset against its historical conditions. Most often, we are interested in future returns, conditional on indicator values above or below a given threshold.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2bd7af1aec2c6990d8b6cd22a36d9c04/21b4d/fut-ret-vs-past-vol.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABEUlEQVQoz61RXUvEMBDs//95gi+eCIXTB1HOtvncZGeOpM01nIp9cGDZTXYymWWH9/GEp8cHvDyfcB5HvJ7fME0zrLE0i6UEzxwDVQJT9Ewx1LvoLaK3XMMhWEPvHIaUEpAikAXckJUIovCisD5jdhmLywBZOC3Y59IMIRRBuXWwYeOhvVEFg2Qal+hCZtZdr9JJqOoqKCK7VvddHw2SlJMRXhbhbFP9aOXX9/Te15F3z3dod/fCpV5sqsLFcXP4p+Bvwu2sAD+nWFwfc3jU8WURSMptKanfx2HwtsC1mq0wboL8D3x8BU6zxVDmjjHWKJZb3Z+NMXDO/chrdcnGOAz9GKr6LZdoYm3S1t85ay5Or5qMYxtjBZpyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "understanding conditional expected returns",
            "title": "understanding conditional expected returns",
            "src": "/static/2bd7af1aec2c6990d8b6cd22a36d9c04/c1b63/fut-ret-vs-past-vol.png",
            "srcSet": ["/static/2bd7af1aec2c6990d8b6cd22a36d9c04/5a46d/fut-ret-vs-past-vol.png 300w", "/static/2bd7af1aec2c6990d8b6cd22a36d9c04/0a47e/fut-ret-vs-past-vol.png 600w", "/static/2bd7af1aec2c6990d8b6cd22a36d9c04/c1b63/fut-ret-vs-past-vol.png 1200w", "/static/2bd7af1aec2c6990d8b6cd22a36d9c04/21b4d/fut-ret-vs-past-vol.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above shows future returns, conditional on past metrics. On the vertical axis, we see the future 5-day return of the eleven S&P 500 sector-ETFs. On the horizontal axis, we show their annualized 5-day historical volatility. The blue line shows the expected performance for volatilities exceeding the value on the horizontal axis. In contrast, the orange line shows the same for volatilities below the value on the horizontal axis.`}</p>
    <p>{`The following examples should help to interpret this chart:`}</p>
    <ul>
      <li parentName="ul">{`The average 5-day return is about 0.15%, as indicated by the grey horizontal line.`}</li>
      <li parentName="ul">{`The expected future 5-day return for volatilities larger than 20% is about average, as indicated by the blue line crossing the grey line at 20% volatility.`}</li>
      <li parentName="ul">{`If volatility exceeds 40%, the expected future 5-day return enters negative territory, as shown by the blue line diving below zero on the vertical axis, when volatility reaches 40%.`}</li>
      <li parentName="ul">{`The expected future return is above average for volatilities below any value between 20% and 70%. We can tell by the orange line staying above the grey line in this volatility range.`}</li>
      <li parentName="ul">{`Volatilities below 8% or above 70% combined occur less than 5% of the time. We consider them outliers and don't include them in the chart.`}</li>
    </ul>
    <p>{`Following the Efficient Market Hypothesis, the lines on this chart should be flat, or random. However, this is not what we see. Instead, we see the blue line trending down, indicating a market anomaly. This anomaly suggests the expected future return is inversely related to volatility: expected return declines with rising volatility.`}</p>
    <h2>{`Defining a Simple Strategy`}</h2>
    <p>{`Once we have established a relationship between an indicator and expected returns, we can attempt to define a strategy. From the orange line in the chart above, we learned that we could achieve above-average returns by limiting volatility to an upper bound somewhere between 20% and 70%. Based on this finding, we can define the following simple strategy:`}</p>
    <ul>
      <li parentName="ul">{`Hold each of the eleven S&P 500 sector-ETFs, with historical volatility below X`}</li>
      <li parentName="ul">{`Hold cash, for any sector-ETF with historical volatility above X`}</li>
    </ul>
    <p>{`Obviously, our strategy does not produce any returns while holding cash. To reflect the impact of holding cash on the total return, we need to time-weight the expected returns. The following chart does just that:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e840ac0753d70c9916b772208338d330/21b4d/fut-ret-vs-past-vol-tw-0.0.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABSUlEQVQoz41Sy1LEMAzr/38jnODAHhiWzcOOY0uM0+2yMAyQqUZJY6muku308oLHxwc8PT/hdDqhtQYAjEgEw50+ZIdUuhS6tjW3XmlSYdIxemVqt/Ol4PVc4e6fRu5wKfBe4FLhQ+CmiIhvAAJgPh7B3js2d2eAbOokiQS0EFOBmPzH2DUIigg2M1svhgUsm9RKuN1X/oVVlvGoKrY5501cL4UxZF8fgrv5j+1dDZNWhmZ7NwznlMYqfrX/2t2x/s1wZXh0GNqWkTmOPL8Lf/3lzHAZ2tDMDTDNnbWpFih9osmEzcAxjlh/QhynbO2NnCVbJEOIkMXhnT47hzaK7LDROMbOiWl91a362agq2PKohxnGGMhTWjzG4gO1VuTXaxOUJotr08XvpeO9CM6Xlj7c1tW8jrysX3mfp9l+vVYq19+/iW6azPoDcDhiRv1byZUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "expected return based on past volatility",
            "title": "expected return based on past volatility",
            "src": "/static/e840ac0753d70c9916b772208338d330/c1b63/fut-ret-vs-past-vol-tw-0.0.png",
            "srcSet": ["/static/e840ac0753d70c9916b772208338d330/5a46d/fut-ret-vs-past-vol-tw-0.0.png 300w", "/static/e840ac0753d70c9916b772208338d330/0a47e/fut-ret-vs-past-vol-tw-0.0.png 600w", "/static/e840ac0753d70c9916b772208338d330/c1b63/fut-ret-vs-past-vol-tw-0.0.png 1200w", "/static/e840ac0753d70c9916b772208338d330/21b4d/fut-ret-vs-past-vol-tw-0.0.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Remembering that the blue line indicates expected returns conditional on volatility above the threshold, we can infer that we hold cash more often the further we get to the right. On the rightmost edge of the graph, we will be all in cash, leading to a return of zero. Similarly, we hold cash for longer times the closer the orange line is to the left edge.`}</p>
    <p>{`For our strategy, we choose to exit to cash at a volatility of 38.5%. At this volatility, the orange line crosses the grey line, indicating that the expected time-weighted return matches the average. With this volatility threshold, we can expect our simple strategy to produce returns equal to buy-and-hold. As we are holding cash in times of elevated volatilities, our resulting equity curve should show lower volatility than buy-and-hold.`}</p>
    <h2>{`Testing the Strategy`}</h2>
    <p>{`We designed our simple strategy based on the statistical distribution of returns and without even considering price movements in the time-domain. The chart below shows how closely the backtest matches our hypothetical results.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4d3195e5e594cf5b0eedb14c14bbd651/21b4d/exit-cash-cum-ret.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACPElEQVQoz42Sz0tUURzF33/VKkjNqIVZLSyoTSshxRxIyrGNBAbVJiioRdGiXVCLCmlEUcYZZ7JEK6IsJ53Rad6b+375vO/3OyfeDEwpBR34wP1+D/fwvdyvousmVFWFYRhwXRdxHKcwBUCHOAGTBEwVJYDjRnTckLlCBfee5Hn7cRm240KJ44gHlQBIkjZxgjSlw9p3GxP3l5m5NY+hqQUeGZ7FocE5nr5egnQDKmEYAn8EoH1xn/TdkPlPJh++WGd/ZppHh9+wd2SGXSPzPHaliO7RPM7fWKZu2FCSJDk4H0srm3z6rMR6w+TMe8HBqUX2DT3n8Usv2TO6wO5MgV2ZArszi+zJ5Hl4eAFnJ99S6BYUSwjuNCWFKlgor3PyQZlnxvPsvTzHC9kcz4295sDYNAey8zyVLbJvfIknx4vsu5aei+zPLvHEWIEXby63n+yYBmdzJVZqBmtNn9/qHrdFQN0lrVqFu7qgZsctGmbIuh5wRw+oWlGr3hE+Uk81A3ieD8VoCuZXNW5sCUaOwVjahDS5XWtwcU1j+YvNhuG3fEcXhGuRnkVRr9MUOhlK1Kt12kIgDNLAxk9M3MlhZbWCcM+CtG2ErsSPz19x9e4SHr3aQLO6hY/vPsDUNATSQew5UKs1NFUdURigurEJs6lT7u2lnxKRocG/KhCkr5GJz/+RYZhQpJRwg6i11F6K18b1AzjaNuSuBemHbd/zfnOgTnN8308XO0YURfvo9NLFjhMEvt/p/4vUT/UL3aEXUmC/QzEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "a simple volatility strategy",
            "title": "a simple volatility strategy",
            "src": "/static/4d3195e5e594cf5b0eedb14c14bbd651/c1b63/exit-cash-cum-ret.png",
            "srcSet": ["/static/4d3195e5e594cf5b0eedb14c14bbd651/5a46d/exit-cash-cum-ret.png 300w", "/static/4d3195e5e594cf5b0eedb14c14bbd651/0a47e/exit-cash-cum-ret.png 600w", "/static/4d3195e5e594cf5b0eedb14c14bbd651/c1b63/exit-cash-cum-ret.png 1200w", "/static/4d3195e5e594cf5b0eedb14c14bbd651/21b4d/exit-cash-cum-ret.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can see how our strategy's total return is on par with buy-and-hold but with lower volatility. To gain more in-depth insight into this strategy's possible outcomes, we need to analyze the statistical distribution of returns. We like to use Monte-Carlo simulations to do so, as these charts provide an intuitive yet comprehensive overview of the risk/ return profile. The following chart shows the compound annual growth rate (CAGR) and maximum drawdown (MDD) for our simple strategy:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/02194f7cb011e5dc9bfb8f1103562837/21b4d/exit-cash-monte-carlo.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACHklEQVQoz32Tz2sTQRTH9z/y6MGbV20rVeqPk9GLEATBg000EhFie6mKIOpBUBQRpOKlWASp1vysaY1pmrQmaZq2kF+bzW6S/TUz7yu7GzVF44PP8ObNG94b3nckWVaoXq+TLMvU6/XJtpkDGBfggg4gCCAARMJd4ZoTZYDVI8MwSGKMYYTRQchDMNJ1E42Whu3dNtYKTZpfqtLc6y3ISpckEHcvNBSDynsdyhZlimVqWIxV8WqxhIdv8ph9nkXwUQaX59K4EEnibDiO8UAcR68s4/ClJTp08SMdn46h2VJISmZrmHmWxdX7X+GLJDEVimIi8AVjgfhfjAcTOHE9gclQEqduJnE6nMK52ys0FU7Bf3eNen2TpPUfDUSzLaxuKfheUpGraChUuyhUtX+z45Hf0bBR0ZDbVmm9rCJfUckwTJIevyvCN7sK30waZ26tuNWdbpwn/I+xQAwTwbjTKZ0MJf90qCga9moaSrsdbJTb+LbZQirXwudMC+9TNbxd3sfLD1U8XajgwXwJkRebmH6Sg/9eBufvpDF5I0FH/J/o2LUoFLVHkuBsMEFnOGLgu1IYxPjAZ0PnXg5njGTVouJ+j5zi9WabJNu2XV0IGkI40G9+leCcgzPbRTAb4DYgLLg6BMGybZJ0XSfLMl1RDmOaJpmm52uaRk6eYZou3pnlDkE3DNL7OnU7iidsGpgQYiT9vvODbG/P+cg8x34CwyEJaETGSXAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "volatility targeting for higher returns and lower risk",
            "title": "volatility targeting for higher returns and lower risk",
            "src": "/static/02194f7cb011e5dc9bfb8f1103562837/c1b63/exit-cash-monte-carlo.png",
            "srcSet": ["/static/02194f7cb011e5dc9bfb8f1103562837/5a46d/exit-cash-monte-carlo.png 300w", "/static/02194f7cb011e5dc9bfb8f1103562837/0a47e/exit-cash-monte-carlo.png 600w", "/static/02194f7cb011e5dc9bfb8f1103562837/c1b63/exit-cash-monte-carlo.png 1200w", "/static/02194f7cb011e5dc9bfb8f1103562837/21b4d/exit-cash-monte-carlo.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This chart confirms our observation from the backtest: This simple strategy provides returns on par with buy-and-hold but at much-reduced volatility.`}</p>
    <p>{`The following diagram shows how the strategy is exiting volatile sectors in favor of holding cash.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/da4da59dfde1567a4294d8a339c0059b/21b4d/exit-cash-exposure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADCklEQVQoz2WT3W9TBQDF75/iiyY+En1AJXHTIJIiLnHA+jEK69beK1tH27F2hdbblraUjnXd2Lq6dv24a9eWtWMfMGwQogNFxDgUFmJcZ2I0oES2sS2awDmG8EL04ZeTk/xy3o6wUvsZv95/iMZjXjZYXJDCChvtp1n95i5JYvm3PyknKjwzXqHBE8EuqZva40GGsjPsimaoMrvRl5+jvX8Ua+uPIVQXvsKN20uQbdvoPPIy2qR3aGrdxpnqLJ4tXl+8A09ARCZhh9teT9eRl2gVX6XDI1GWNbSJryAa83CgV4/V9XUIyeI5zF+9At/hnZS1OxAwvk6H+jVWJtN8uPGEE7OXeMqhpxKV6Rf3UFa/Rbd2O09YdPRZdHSpt+OUbGWkx4S1tUcQMrkEE9kU9XX1aKl/l+27d9JQ9yajw1ku//6AsXSGh7UmDnh8NDfs5YEd71Gse4M27T5K+9Vsq38bdqOGToMeG+urEHJxPwb73FAd38e9/oNoaN/P97ua2Br0cunuj+yLn6HGb6RzLMwmzyGqwlrucTRRYzOwyaHnh24NREcLOiKd/OPBfQijwyOIhLohJfpoLmRoSJ2mqAyw3dvB6uV5Bvrt7BjpZ9engxTjEVpLeYrKMMWRIDuGZErjCUgBL13ZLP96tAohnTpLVyjML8oqLs7v5tS0iVcvHqTs/oDjUxV6I3aeT+9iOX2An51T8adLdVy40MhkrpO5tIZfX/wIvbFuLsxJ2NzahHBlNoxkzIZbignfK+1YnlDhXrEVroAJUSWJnoEAJkesUM724POEGbeULtzLN+PLlBrV0Wb8kBMxHZf43aQT/2ytQqgoKdplH0t2I6eO6li26jhrbWbwRCc/6Q0xFvyYY90mjjnbWDyqYdlyiOctWmaPGZixtrBoM3LQrOOMz4KtjccQpi7fQX/+BkLFGvwTNZycqCFQqKFSmsP0VBk3i2nEC98iP3kNkcISThZW4Cv8gqHSIpKlm/AVVuBN38bQ9DI2t/6G8OwNz3n6H/gCT/B/78X+PIGn+Bc0S2eNnGtBGgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "exit stocks in periods of high volatility",
            "title": "exit stocks in periods of high volatility",
            "src": "/static/da4da59dfde1567a4294d8a339c0059b/c1b63/exit-cash-exposure.png",
            "srcSet": ["/static/da4da59dfde1567a4294d8a339c0059b/5a46d/exit-cash-exposure.png 300w", "/static/da4da59dfde1567a4294d8a339c0059b/0a47e/exit-cash-exposure.png 600w", "/static/da4da59dfde1567a4294d8a339c0059b/c1b63/exit-cash-exposure.png 1200w", "/static/da4da59dfde1567a4294d8a339c0059b/21b4d/exit-cash-exposure.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Introducing a Safe Asset`}</h2>
    <p>{`The main criticism of this simple strategy is the inefficiency of holding cash. An obvious improvement is introducing a safe asset: We replace sectors with a long-term U.S. Treasury Bond if the sector's volatility exceeds our threshold. This specific change leads to an immediate improvement of the equity curve:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9e1368fdaec2fd88ea57d5b419185dd7/21b4d/exit-tlt-cum-ret.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACUklEQVQoz42SS2sTURiG50cJLYrSdmFbRSkUNwpCF0VThVZTiyK66caFILpwJ+5cCF5K68K09JLYiNZeaAy9pGliG3Ofk8x9JjPzvjLpxXoDH3j4ON855+Uc+CRZFiiVyhBCwDRNeJ5Hz/OaFUBT39/zgExew0pKcCVV46OXKVx5uMSbTxahagYkz3P5G9gPQaDrIUhqminoePAihe6RONsGY2gfjLLj2iS6QmO8fG8aumFDajQazcPBZc/zcfQlB6TzBu8/X2fnrThPhqZ47sZ7Xgi/Y084wjPDUXQMRXFxdIGyUCC5nv9nwG6Nc5+2+TFR4dOxLHvvxtk+EGFPeJK9I1PsHo6yM/yBpwOH59E+FMOl0QVW5DokS1MYZFZlleMzKd5+HGfXYIQn+t7wVP8EW/rG2Xn1Lc9eH2fbQIQtoTm2hmbZGppp1uMDszjWP83zd+aDL1OqyYKJpSRz36tcXKswnhT8sqnya67BdHKDmfQuExmNK2mdy4FbKpe2VK5mdK5ua1xOKUhkDSazGmzbgVQtVRhbLlIIhb5RJx2NdBSWC2V+Tpa5mtGoGC7VukpfF6StkK5KpVykKsukZ6KUK9BSBDy3AUkUCxibWEAlX4BRr8FQFDimjuzaBp69TuDVzA7k3DfkN9ehiyosTYNraiju7KJUqMKxHWRTWdSrMnVdh+T7LukK/pVGlbTKpG/xfxCiBskwDJi2C9O0YJkmLGtP03aglXMw1DoMu9Ecesuyfnp0bVoIcmzbDgbbg+u6v3jY259Nx7YP+/8y2A/4AedaEEoePHgYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "treasury bonds as a safe haven",
            "title": "treasury bonds as a safe haven",
            "src": "/static/9e1368fdaec2fd88ea57d5b419185dd7/c1b63/exit-tlt-cum-ret.png",
            "srcSet": ["/static/9e1368fdaec2fd88ea57d5b419185dd7/5a46d/exit-tlt-cum-ret.png 300w", "/static/9e1368fdaec2fd88ea57d5b419185dd7/0a47e/exit-tlt-cum-ret.png 600w", "/static/9e1368fdaec2fd88ea57d5b419185dd7/c1b63/exit-tlt-cum-ret.png 1200w", "/static/9e1368fdaec2fd88ea57d5b419185dd7/21b4d/exit-tlt-cum-ret.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`In addition to improving the return, we also further reduced the downside. This is possible because long-term Treasuries are negatively correlated to the stock market, making them an excellent choice for a safe asset.`}</p>
    <h2>{`Inverse Volatility-Weighting`}</h2>
    <p>{`While we made good progress with improving the simple strategy, some critical issues remain:`}</p>
    <ul>
      <li parentName="ul">{`Volatility threshold too far out. The current limit of 38.5% volatility is at the 88th percentile, raising the question of whether we optimized our strategy to filter outliers.`}</li>
      <li parentName="ul">{`Hard transition between sector-ETFs and safe assets. We aim to avoid hard transitions, as these might react to tiny differences in calculation results and lead to unexpected behavior.`}</li>
      <li parentName="ul">{`Transition to the safe asset instead of increasing the weight of low-volatility sectors. This conflicts with our goal of holding stocks as much as possible.`}</li>
    </ul>
    <p>{`To solve these issues, we need to revisit the conditional expected returns from above. We notice the following characteristics:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`r`}<sub>{`5d, vol > x`}</sub>{` < r`}<sub>{`5d, vol < x`}</sub></em>{` with `}<em parentName="li">{`x > 20%`}</em><br />{`
The chart shows this by the orange line being above the blue line.`}</li>
      <li parentName="ul"><em parentName="li">{`r`}<sub>{`5d, vol > x`}</sub>{` < r`}<sub>{`5d, vol > y`}</sub></em>{` with `}<em parentName="li">{`x > y`}</em>{` and `}<em parentName="li">{`y > 20%`}</em></li>
    </ul>
    <p>{`These two findings express that volatility is an excellent ranking factor: For volatilities exceeding 20%, we can expect returns to decrease with increasing volatility. To turn this into a strategy, we weigh the eleven sector-ETFs inversely with their historical volatility. Also, we gradually reduce the overall exposure, based on the total volatility across all sectors:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/12d11ac082cec07dd2e7f5c2850f57b0/21b4d/vol-par-cum-ret.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACT0lEQVQoz42Sy0uUURjGvz/KhQhiRCWUodAiwo1EQmNRllZYEIS7Fi2Sli3atIoESUKZhhFjRscLoo1zycuMDs31u9/OfJfniU9FNAr6wY/Dec55X86BV5IVFdVqDbpuIAxDBkGAMxIAQ4BBCJKRhGYK7v0yuFFU+G56F3der3PkzRoMy4UUBD7PcFwBMgyPGiGIuh3naKgC72fLvDW5iqvP0ux5uMiee3Fcis1y6GUCli0oeZ53dDkMEcng+Cnn2C4ZfPUhz/4Xy+yKJdj7YJ4Dj76yf2yefeNJ9I5+w+BkhrJqQPKD4M96aobD3M865zI1vv28x4HnKXbH4ux7HOeNp3Fef5LktfEF9o4t8vJ4ChdGv2NwcoUtWYPUts2j/9SbBpOZMqc+bvDmRILdt6fZPfyFHUOzvHJ3hn33Z3hxZI6dsSQ7Ywsna5JdI0l0DCfYP5GC7bQp7ZfrLP4osHTQZHqzxsR6gwubCld2HG6tb7OYL3GtoHIprzOV05neVpnKqswUdC7nNKazCpbzOteKGoRoQzooV7mSbVDXDHqmRgqTbOtUm01md2WuFnXKhkdV0RlaKil00tNpNmsUhkoGDrR6nZ6pwvc8SIelClaXtqA36jBkGZauQ9gWGns7mFvcwdSnAmr7JRzmc7CVFlzLhG+baFYqUFsKhCtQLR3AVBSapgmJCEhP5l8RLdJtkKHg/6CoKiTLtuG0fTiOC9dxTnVEG1ajAtvQYAsPTpS77hnP723bhhAiGuwAvu+f8zQLgWgu20Kc5v8yOo/4DVbjEQHsLsedAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "volatility targeting for higher returns",
            "title": "volatility targeting for higher returns",
            "src": "/static/12d11ac082cec07dd2e7f5c2850f57b0/c1b63/vol-par-cum-ret.png",
            "srcSet": ["/static/12d11ac082cec07dd2e7f5c2850f57b0/5a46d/vol-par-cum-ret.png 300w", "/static/12d11ac082cec07dd2e7f5c2850f57b0/0a47e/vol-par-cum-ret.png 600w", "/static/12d11ac082cec07dd2e7f5c2850f57b0/c1b63/vol-par-cum-ret.png 1200w", "/static/12d11ac082cec07dd2e7f5c2850f57b0/21b4d/vol-par-cum-ret.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`While this improvement has not done a lot to improve returns further, our confidence in robust out-of-sample results has risen much.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/870de8aa08a4b43d868a903773fe4565/21b4d/vol-par-monte-carlo.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACJElEQVQoz4WS0UsUURTG578q7LXCjKxIsKAgeijzoWWNNDYsDQp8UCqjhyiCXiTBhx6CiHS11NHdZdc2213N0jS1TXdmdp3Zmb135vtixlU2sjrw45xz73cP53KOUijoWF1bh24UIaVLISSElADA/1ggADzohsXR2BKMogWFcP8Q+eZ6RNEUyGsOlvMW5pa3kJwrIJpYw1D0Gx8NzfPm4xm23U+guXMCTZ0qC/oWlA29jFhWw4uRFdwbnGfX0zTCD5K82BPn2e4JNkXe8WTHGA+Fhnmg5S3rWoZ32dcSZV3rKPZfHsPp7hg3NnUovQMZHrwywoa2KOvDUR4OjwbUXx1j/bVxHmmf4NGOSR67PsnGiMrGyFQNKo9HVDS0T/L83Ti2LBtKKvuD7z/8pDpboPqpwKmMxumsxlhWZzynMZHTAh+Q3YOchumMxuScBtt2oDx/vchQ/0eG+tO81DvDCz1JnruToP+FU51Tfgf/5MQNFb5v7UvB9Du0LZPFosX8psmV9RK/rBjMLWqc/Wow9dkIuhhPb/JNPM+X42scGP7OJ6+W2De4wFvPsgw/TOPM7Tiau6apGSYUKSX84ZNuFa+KH8savxOjhmArIF1gYdXcHooQYndfUFMuwEMAanJXSrpSbCME3YpNuhWSAhXHgVIul1GpOLBt+zccxwnw41KpBF8X3AXnlQDb2XnnwCwVtwuiap7n/RXLsiCEqObunhq36n8BbJL9RTE/qhsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "volatility targeting for lower risk",
            "title": "volatility targeting for lower risk",
            "src": "/static/870de8aa08a4b43d868a903773fe4565/c1b63/vol-par-monte-carlo.png",
            "srcSet": ["/static/870de8aa08a4b43d868a903773fe4565/5a46d/vol-par-monte-carlo.png 300w", "/static/870de8aa08a4b43d868a903773fe4565/0a47e/vol-par-monte-carlo.png 600w", "/static/870de8aa08a4b43d868a903773fe4565/c1b63/vol-par-monte-carlo.png 1200w", "/static/870de8aa08a4b43d868a903773fe4565/21b4d/vol-par-monte-carlo.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Monte-Carlo simulation shows that the risk/ return profile has also improved: The curves for CAGR and MDD have become flatter, and as a result, the Sharpe Ratio has increased.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/55a3cfa3de4d51c52fa71fcf771c1994/21b4d/vol-par-exposure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADAElEQVQoz4WM209bBQCHz3/hw17dyx72ssQHTabd1HhBWAti6miFQWUtvQo9h3M65kpb2rLRlrK10BbKpe3poWMwBmO4MGUXFwUXY0wWN/XFF4xuhrZTF+bv54OJj/rwJV/yJZ+weXcL93/8CXopwZeswzhqj/LlnmGWN77is709LN28R3e8SH/+KluUFJpcAzxqPUMxtcT2oQJftwcQLV6nJ17Abq0OYXH9Gu4/fIDTnkP02fYh5HqOjq7nWVldwtM9ULs0iWjQgHxOREA8SNm2n+6uAzwbc9Ajmyn37Ed+6hQn4sdRrdUgFOdLuPPFl+i3GSh26uDtfIPOEzqurcyw/sfvXFAjDMp6zmd7Gel7hf1th9nbepgjARcVewsl82uYuDDERNT7z3BWLfHK2hqVYy/C+HYznY06OhteYEGdY7X2iDP5UTpsVuZiQfZ3tNLc+BbduoNUnB462gz0HDmEsE9mVHShVq1CKE+dx6UFDa82Waj3GNFkNbHB2MDwZI6Pfv2ZF7JpHuvwMDSSYm/nO2xVWtlsbWa3z0uTy8gjdhNcXhn+j05wZ2cHgjoWgZpPw+BP0pQa5wfZ02wZdlNKxvjg4Q8MJDM0nbIwnM/yfVcfO8ciNJ8dYE9ilLZ4Hz8shmCPBOnyD/Lxb48hpGMJFnNjLJVPcnmxm0ltkHIuSGXEzdtb31AcydA5JjGsZtkd8nGu3MHxvIX+dIBKKsxCxQclMcTg+XN4Uq9CGIxPw+8P4k7RiNriAdS1ffh64U10e90oLW8gmY4jMRlDZsaPVMaL1ZKM7RkzLo5bcGuqEd9pzRgddXM904Gn1V8gxGJJWh0ik74BfirreVlq4SfRNr57vIvn0tNcD7VzM/Qe5yU9Pzuj4+zHJ7khGjirWKjK7UxJEt0ekbmAhPruLoR7m+sozK8gr91FWdtEvvw5Fi/fwMS0huWr1/DtcgZblSyuaxpuVFRMXNxGpryNlYVVzFVuY0j9HuPFDcxe2ULtyZ8QSOL/ePafHf868Bf+Bp34ZRzyQjUQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "volatility targeting can be tax efficient",
            "title": "volatility targeting can be tax efficient",
            "src": "/static/55a3cfa3de4d51c52fa71fcf771c1994/c1b63/vol-par-exposure.png",
            "srcSet": ["/static/55a3cfa3de4d51c52fa71fcf771c1994/5a46d/vol-par-exposure.png 300w", "/static/55a3cfa3de4d51c52fa71fcf771c1994/0a47e/vol-par-exposure.png 600w", "/static/55a3cfa3de4d51c52fa71fcf771c1994/c1b63/vol-par-exposure.png 1200w", "/static/55a3cfa3de4d51c52fa71fcf771c1994/21b4d/vol-par-exposure.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The diagram above shows how the strategy smoothly adjusts sector weights in favor of low-volatility sectors. Further, it shows the strategy existing S&P sectors and holding the safe asset instead.`}</p>
    <h2>{`Wrap-Up`}</h2>
    <p>{`This post shows that the U.S. stock market has an anomaly regarding volatility: Expected returns are dependent on historical volatility. Further, we have demonstrated step-by-step how to use these findings to design a profitable strategy, which meets our original goals and intent.`}</p>
    <p>{`We hope this post has provided some valuable insight into our approach to quantitative trading. The findings in this post form the foundation of our following premium strategies:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/tt-dos-equis/"
        }}>{`TT's Dos Equis`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/tt-equal-voltage/"
        }}>{`TT's Equal Voltage`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/tt-vix-treasures/"
        }}>{`TT's VIX Treasures`}</a></li>
    </ul>
    <ButtonPrimary text="Try Dos Equis" to="/portfolios/tt-dos-equis" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      